<template>
  <section id="experience" v-if="experienceCollection && experienceCollection.isAllowed">
    <PageTitle :title="experienceCollection.title" :icon="experienceCollection.icon"/>
    <v-container>
      <v-timeline
        :class="$vuetify.breakpoint.smAndDown ? 'ml-n8' : ''"
        dark
        align-top
        :dense="$vuetify.breakpoint.smAndDown"
      >
        <v-timeline-item
          v-for="(item, i) in sortTimeline(experienceCollection.timeline)"
          :key="i"
          small
          :color="item.color"
        >
          <v-card
            :class="$vuetify.breakpoint.smAndDown ? 'ml-n2 mt-n1' : 'mt-n1'"
            dark
            :color="item.color"
          >
            <v-card-title class="title pl-3 py-2 black--text">
              {{item.title}}
            </v-card-title>
            <div class="subtitle-2 black--text pl-3 mt-n2">{{item.role}}</div>
            <div v-if="typeof item.endDate == 'string'" class="caption black--text pl-3 pb-2">
              {{formatDate(item.startDate)}} - {{item.endDate}}
            </div>
            <div v-else class="caption black--text pl-3 pb-2">
              {{formatDate(item.startDate)}} - {{formatDate(item.endDate)}}
            </div>
            <v-card-text class="lighten-4 text--primary" style="background-color: #353535">
              <div class="my-2" style="color: #ddd" v-html="item.description"></div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-container>
  </section>
</template>

<script>
import firebaseGetCollection from '@/mixins/firebase8/collection/get';
import { format } from 'date-fns';
import PageTitle from '@/components/shared/PageTitle.vue';

export default {
  name: 'Experience',

  components: {
    PageTitle,
  },

  data: () => ({
    experienceCollectionRaw: null,
  }),

  computed: {
    experienceCollection() {
      return (this.experienceCollectionRaw && this.experienceCollectionRaw.results)
        ? this.experienceCollectionRaw.results[0]
        : null;
    },
  },

  async created() {
    await this.subToCollection();
  },

  destroyed() {
    this.unSubToCollection();
  },

  methods: {
    async subToCollection() {
      this.experienceCollectionRaw = await firebaseGetCollection('experience');
    },

    unSubToCollection() {
      this.experienceCollectionRaw = null;
    },

    formatDate(date) {
      if (date) return format(date.toDate(), 'dd/MM/yyyy');
      return 'current';
    },

    sortTimeline(arr) {
      let res = [...arr];

      res = res.sort((a, b) => (b.startDate > a.startDate ? 1 : -1));

      return res;
    },
  },
};
</script>

<style>
.custom-list {
  list-style: none; /* Remove default bullets */
  padding-left: 0;   /* Remove default padding */
}

.custom-list li {
  position: relative;
  padding-left: 20px; /* Space for custom bullet */
  margin-bottom: 10px;
}

.custom-list li::before {
  content: '•';          /* Bullet character */
  position: absolute;
  left: 0;               /* Position at the very left */
  color: #3498db;        /* Bullet color */
  font-size: 30px;       /* Bullet size */
  line-height: 0.75;        /* Vertical alignment */
}
</style>
